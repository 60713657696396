import { axios, API_ROUTES } from "@/config";

/**
 * Consult the data of footer
 * @param {string} params - Gets the hash SHA-256.
 * @param {string} id - Gets the name of the farm.
 */
export default {
  // async getAll(params, id) {
  //   let result = await axios.get(`${API_ROUTES.mainOptionNameId.get}/${id}`, {
  //     params: params,
  //   });

  //   return result;
  // },
  async getAll(params) {
    let result = await axios.get(`${API_ROUTES.footer.get}`, {
      params: params,
    });

    return result;
  },
};
